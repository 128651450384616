* {
  
  box-sizing: border-box;
  font-family: -apple-system, "Montserrat", sans-serif;
  
}

/* Replace with the path to your Font Awesome CSS file */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");

footer {
  margin-top: auto;
  width: 100%;
  background-color: var(--primary-color);
  display: flex;
  justify-content: flex-end;
}
footer a {
  color: white;
  text-decoration: none;
  margin: 10px 20px;
}

body {
  font: 16px/1.65em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial,
    sans-serif;
  height: 100%;
}
:root {
  --header-height: 3.5rem;
  --primary-color: #2b3965;
  --secondary-color: #D0D2DB;
  --accent-color: #b33f48;
}
input[type="date"] {
  font-size: 15px;
}

input[type="checkbox"]{
  width: 1.5rem;
  height: 1.5rem;
}
input[type="checkbox"]:hover{
  background-color: #919191;
}

input[type="datetime-local"],
input[type="text"],
input[type="search"],
input[type="tel"],
input[type="number"],
input[type="password"] {
  background-color: #fcfcfc;
  color: #919191;
  font: 1em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  border-radius: 0px;
  -webkit-text-fill-color: #666666;
  border: 1px solid #ddd;
  padding: 0 12px;
  cursor: text;
  font-size: 15px;
  height: 40px;
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  position: static;
  outline: none;
  padding: 0 12px;
  display: block;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  outline: none;
}
.btn,
.btn-style {  
  margin: 3px 2px;
  margin-left: 5px;
  border-radius: 3px;
  line-height: 1.2em;
  text-align: center;
  outline: 0;
  font-size: 13px;
  background-color: white;
  --content-color: var(--primary-color);
  color: var(--primary-color);
  border-color: #e1e1e1;
  border: 2px solid var(--primary-color);
  vertical-align: baseline;
  cursor: pointer;
  position: relative;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);  
  min-height: 2rem;
}

.btn:hover,.btn-style:hover {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-color);
  --content-color: white;
}
.btn-logo {
  fill: var(--content-color);
}

.body {  
  padding: 1em;
  position: relative;
  display: flex;
  justify-content: center;
}

.container {
  position: relative;
  margin: auto;
}

.container > .container-split {
  width: 100%;
  padding: 1rem;
}

.full-width.container {
  max-width: 100% !important;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.back-button {
  background-color: white;
  border: none;
  color: var(--secondary-color);
  padding: 10px;
  text-align: center;
  text-decoration: none;
  border: 1px solid var(--primary-color);
  display: inline-block;
  font-size: 16px;
  text-align: center;
  margin: 4px 4px 4px 0;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: var(--primary-color);
  color: white;
}

.back-button i {
  margin: 0 2px;
}

.pos-wrapper{
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}
.pos-title{
  font-weight: bold;
}
.equal-column {
    flex: 1;
    margin: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.tabs {
  background-color: var(--secondary-color);
  border-radius: 5px;
  padding: 2px;
}

.tabs > .tab-button:first-child {
  border-left: none;
}

.tabs > .tab-button:last-child {
  border-right: none;
}

.tab-button {  
  font-weight: bold;
  font-size: 16px;
  box-sizing: border-box;
  flex: 1;
  padding: 5px 10px;
  text-align: center;  
  transition: all 0.3s ease-in-out;
  color: var(--primary-color);
  padding: 5px auto;
  overflow: hidden;
  display: inline;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;  
  border: 1px solid var(--primary-color);
  border-top: none;
  border-bottom: none;
  margin: 2px 3px;
  background-color: var(--secondary-color);
}

.tab-button.select{
  color:white;
  text-decoration: underline;
}

/* NAVBAR --------------------------------------*/


.topnav {
  overflow: hidden;
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  z-index: 100;
  width: 100%;
  flex-wrap: wrap;
}

.topnav a {
  float: left;
  display: block;  
  height: var(--header-height);
  color: #f2f2f2;
  text-align: center;
  padding: 14px 10px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: var(--primary-color);
  color: white;
  font-weight: bold;
}

.topnav .icon {
  display: none;
}


.dienstatDiv {
  float: left;
  display: inline-block;
}

.dienstatPopup{
  position: absolute;
  transform: translateY(50px);
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid black;
  border-radius: 5px;
}

.dienstatPopup > * {
  padding: 15px;
  font-size: 1rem;
  margin: 0 3px 0 0px;
  
}


.dienstat-btn-wrapper {
  border-radius: 5px;
  justify-content: space-between;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
  background-color:white;
}

.dienstat-btn-wrapper.active {
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.dienstat-btn{
  padding: 10px;
}
.dienstat-display {
  margin-left: 2px;
}
.leaderboard-wrapper {
  display: inline-flex;
}



.no-scroll {
  overflow: hidden;
}
.card {  
  background: var(--secondary-color);
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  padding: 0.5rem 0.7rem;
  border-radius: 8px;
  margin: 0 0 1rem 0;
}

.login-form {
  padding: 1.3rem 0.7rem;
  margin: auto;
  width: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.login-form > * {
  margin: 0.3rem 0;
}

.abw-admin-header {
  position: sticky;
  background: white;
  top: 0;
  z-index: 5;
  border-bottom: 1px solid black;
}

.abw-admin-header .abw-admin-row > * {
  height: 40px;
  line-height: 20px;
}

.abw-admin-header-nav {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 1rem 0;
}
.abw-admin-header-nav > * {
  margin: 0 0.2rem;
}
.abw-admin-row {
  display: flex;
  position: relative;
  width: max-content;
}
.abw-admin-row:nth-child(odd) {
  background-color: lightgray;
}
.abw-admin-row:last-child {
  border-bottom: 1px solid black;
}
.abw-admin-row div {
  border-top: 1px solid black;
  border-left: 1px solid black;
  cursor: pointer;
}
.abw-admin-row div:last-of-type {
  border-right: 1px solid black;
}
.abw-admin-row div:hover {
  background-color: gray;
}

.planung-messe {
  max-height: 16rem;
  width: 100%;
}
.planung-messe > * {
  float: left;
}
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  background: rgba(148, 144, 144, 0.5);
}
.popup-content {
  padding: 2rem 1rem;
  position: absolute;
  background: white;
  box-shadow: center;
  width: fit-content;
  max-height: 98vh;
  overflow: scroll;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
}
.popup-close-button {
  position: sticky;
  top: 0rem;
  left: calc(95% - 0.3rem);
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.bestenliste-table {
  width: 100%;
  max-width: 600px;
  border-collapse: collapse;
}
.bestenliste-table th:first-child {
  width: 6rem;
}

.messe-btn{
  margin: 0.5rem 0;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  border: 1px solid black;
  background-color: white;
  cursor: pointer;
}
.messe-btn:hover{
  background-color: lightgray;
}

.edit-messe > div {
  display: flex;
  justify-content: space-between;
}
.edit-messe {
  display: flex;
  flex-direction: column;
}
.edit-messe > * {
  margin: 0.2rem 0 !important;
}

.edit-form td > input,
.edit-form td > .tags-input {
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
}
.day-highlight {
  background: rgba(238, 105, 105, 0.5);
}

.inactive-user {
  text-decoration: line-through;
}
.table-alt {
  background: lightgray;
}

th {
  text-align: left;
}
thead {
  position: sticky;
  top: 0;
  background: white;
  z-index: 5;
}
/* Spinner style*/
.css-yrmx0p {
  position: fixed !important;
  top: 50%;
  transform: translate(-50%, -50%);
}

.messe-planung tbody > div:nth-child(even) {
  background: lightgray;
}
.messe-planung {
  margin: 1em 0;
}
.messe-planung select {
  margin: 0 1em;
  width: 10rem;
  background-color: #fcfcfc;
  color: black;
  font: 1em "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-radius: 0px;
  -webkit-text-fill-color: black;
  border: 1px solid #ddd;
  padding: 6px 2px;
  box-sizing: border-box;
  box-shadow: none;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
}

.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 480px;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;
  background-color: white;
}
.tags-input:focus-within {
  border: 1px solid #2B3965;
}
.tags-input input {
  flex: 1 !important;
  border: none !important;
  height: 40px !important;
  margin: none !important;
  font-size: 12px !important;
  padding: 4px 0 0 0 !important;
  /* width: 100%; */
}
.tags-input select {
  background-color: #2B3965;
  color: white;
  padding: 8px;
  border: none;
  font-size: 12px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 6px;
  margin: 8px 8px 2px 0;
}
.tags-input select option{
  padding: 30px 0;
}

.tags-input input:focus {
  outline: transparent;
}
#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag {
  width: auto;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 12px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 2px 0;
  background: white;
  border: 1px solid var(--primary-color)
}
.tag .tag-title {
  margin-top: 3px;
}
.tag .tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  color: #2B3965;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
}


/*Table style*/
@media screen and (max-width: 1200px) {
  .container > .container-split {
    width: 100%;
  }
  .container {
    min-width: none;
  }
}

/*Mobile style*/

@media only screen and (max-width: 600px) {

  .equal-column {
      flex: 100%;
  }
  .tab-button { 
    display:block;
    border: none;
   }
  .container {
    min-width: none;
  }
  .container > * {
    max-width: 700px;
  }
  .edit-form td input,
  .edit-form td > .tags-input {
    min-height: 48px;
    width: 100%;
    border: 1px solid rgb(214, 216, 218);
    border-radius: 6px;
  }
  .tags-input select {
    width: 100%;
  }
  .edit-form th {
    font-size: 14px;
    flex: 1;
    min-width: 7rem;
  }
  .edit-form td {
    padding: 0 8px;
    position: relative;
    flex: 4;
  }
  .edit-form th:last-child > * {
    width: 100%;
  }
  .btn {
    min-height: 3em;
    min-width: 3em;
  }
  .edit-form tr {
    margin: 4px 0;
    width: 100%;
    display: flex;
  }

  .profile-message {
    position: fixed;
    color: white;
    width: 100%;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .topnav a:not(.active){
    display: none;
  }
  .topnav a.icon {
    width: 50px;
    float: right;
    display: block;
  }
  .header {
    display: inline;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
  .topnav.responsive div {
    display: block;
  }
  

  .tags-input {
    width: unset;
  }
}

