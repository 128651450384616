.dien-menu {
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.dien-container {
  width: 100%;
  margin: auto;
}

.dien-container * {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
  color: white !important;
  --display-fontsize: 15pt !important;
}
.dien-display,
.dienablauf-btn {
  background-color: #2b3965;
  border-radius: 33px;
  font-size: var(--display-fontsize);
  padding: 0.5rem 0.5rem;
  margin: 0.5rem auto 0 auto;
}
.dien-display{
  margin: 0 auto !important;
}

#hm .btnrow {
  justify-content: center;
}

.btnrow {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
}

#descDisplay p {
  width: 100%;
  text-align: center;
}
#descDisplay a {
  cursor: pointer;
  font-style: italic;
}
#descDisplay a:hover {
  color: gray;
}

#quiz > .dienablauf-btn{
  background-color: rgb(0, 89, 255);
  width: 30rem;
}
.dienablauf-btn{
  height: 90px;
  border: none;
  color: white;
  cursor: pointer;
  margin: 0;
  padding: 1rem;
  width: 17rem;
}
.dienablauf-btn:hover {
  filter: brightness(0.9);
}
.slidecontainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20%; /* Width of the outside container */
  text-align: center;
}

/* The slider itself */
.slider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 15px; /* Specified height */
  background: #d3d3d3; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 10px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
.bottom-part {
  width: 100%;
  position: absolute;
  top: calc(60vw + 3rem);
  background: white;
}

#bottomDisplay,
#descDisplay {
  position: relative;
  width: 70%;
  margin: auto;
}

#prev {
  left: -3rem;
}
#next {
  right: -3rem;
}
.slider::-moz-range-thumb {
  width: 10px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #04aa6d; /* Green background */
  cursor: pointer; /* Cursor on hover */
}
#state {
  text-transform: uppercase;
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  text-align: center;
  background-color: transparent;
}
#play.blur {
  background-color: rgba(0, 0, 0, 0.3);
}
#play {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  z-index: 100000;
  transition: 2s;
}
#play > div {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #2b3965;
  cursor: pointer;
  width: 18%;
  height: 30%;
  z-index: 1000001;
}
#play > div::after {
  position: absolute;
  height: 50%;
  width: 45%;
  top: 50%;
  left: 33%;
  transform: translateY(-50%);
  background: white;
  content: "";
  border-top: 1px solid black;
  clip-path: polygon(100% 50%, 0% 0%, 0 100%);
}

#timeDisplay {
  color: black;
  text-align: center;
}

#description,
#description > * {
  text-align: center;
  color: white;
}
#description {
  position: absolute;
  top: -6%;
  z-index: 200;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  font-size: 20pt;
}
.nav-button {
  position: absolute;
  height: 3rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2.3rem;
  background: #2b3965;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  margin: 0.4rem;
}
.nav-button:hover {
  outline: 1px solid black;
}
.nav-button.disabled {
  display: none;
}

#progressbar {
  width: 80%;
  margin: 0.5rem auto;
  height: 2.5rem;
  position: relative;
  border-radius: 1.25rem;
  border: 2px solid #707070;
  background: #9b9b9b;
  overflow: hidden;
}
#progress {
  height: 100%;
  background-color: #5b6da8;
  border: 2px solid #5b6da8;
  border-radius: 1.25rem;
}
#altarraum {
  border-radius: 1rem;
  height: 60vw;
  background: #f0f0f0;
  position: relative;
  overflow: hidden;
}
#altarstufe {
  background: #e0e0e0;
  height: 36%;
  width: 33%;
  position: absolute;
  left: 33.5%;
  top: 15%;
}
#altar1 {
  background: #c9c9c9;
}
#ap-display {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  margin: 0;
}
#ap-container {
  position: absolute;
  right: 7%;
  top: 2%;
  cursor: pointer;
}
#altar {
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 20%;
  height: 16.6666666%;
}
.tisch {
  background: lightgray;
}
.stuhl {
  background: #a78b73;
  position: absolute;
  height: 5%;
  border-radius: 0.2rem;
}
.knie {
  border-radius: 0.2rem;
  background: #717171;
  position: absolute;
  height: 2.5%;
}
#p-sitz {
  width: 15%;
  height: 6.666666%;
  top: 21%;
  left: 8%;
  transform: rotate(-20deg);
}
#alt-sitz {
  background: #a78b73;
  position: absolute;
  height: 33%;
  border-radius: 0.2rem;
  width: 3%;
  top: 39.5%;
}
.k-sitz {
  width: 20%;
  bottom: 11.666666%;
}
.k-knie {
  width: 20%;
  bottom: 20.83333333%;
}
.ablauf {
  padding: 10px;
}
.ablauf.dien-display {
  display: flex;
  justify-content: space-evenly;
}
#k-sitz {
  left: 15%;
}
#k-sitz2 {
  right: 15%;
}
#k-knie {
  left: 15%;
}
#k-knie2 {
  right: 15%;
}
#ako-sitz {
  width: 16%;
  height: 6.6666666%;
  top: 24%;
  right: 8%;
  transform: rotate(20deg);
}
#gabentisch {
  top: 3.33333%;
  position: absolute;
  width: 5%;
  height: 12.5%;
}
#ambo {
  width: 5%;
  height: 5%;
  top: 61.66666%;
  position: absolute;
  left: 10%;
}
#akotisch {
  width: 12%;
  height: 10%;
  position: absolute;
  left: 88%;
  top: 55%;
}

.altar-knie {
  width: 2%;
  height: 28%;
  border-radius: 0.2rem;
  position: absolute;
  top: 22%;
  background-color: #717171;
}
.altar-knie.left {
  left: 34%;
}
.altar-knie.right {
  right: 34%;
}
.akolyte,
.kerzentraeger {
  background: #6775a2;
}
.altardiener,
.kreuztraeger,
.kollektant {
  background: #2b3965;
}
.kerze {
  background: yellow;
  border-radius: 50%;
  border: 1px solid white;
  width: 50%;
  height: 50%;
  position: absolute;
  z-index: 20;
  font-size: 50%;
  text-align: center;
  color: black;
}

.navigation {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
}
.navigation li {
  cursor: pointer;
  filter: brightness(0.8);
  padding: 0 !important;
  margin: 0 !important;
}
.navigation li:hover {
  filter: brightness(1);
}

.ablauf.dien-display {
  display: flex;
  justify-content: space-evenly;
}
.ablauf.dien-display > div {
  width: 50%;
  padding-left: 5rem;
}
.tablett {
  width: 3%;
  height: 3%;
  position: absolute;
  background: silver;
}
.schale1 {
  border: none !important;
  height: 50% !important;
  width: 30% !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
}
.schale2 {
  border: none !important;
  height: 50% !important;
  width: 30% !important;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translateY(-50%);
}
#standtablett {
  top: 11%;
  transform: rotate(90deg);
  left: 2%;
}
.standkerze {
  background: yellow;
  border-radius: 50%;
  border: 1px solid white;
  width: 1.2%;
  height: 2%;
  position: absolute;
  z-index: 20;
}
.mensch {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  width: 2.5%;
  position: absolute;
  height: 4.1666666%;
  z-index: 10;
}
.mensch::after {
  position: absolute;
  height: 60%;
  width: 60%;
  top: 13%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  content: "";
  border-top: 1px solid black;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.verneigung {
  background-color: red !important;
}
.priester {
  background-color: rgba(206, 50, 206, 0.582);
  border: 1px solid black;
}
.evangelium {
  position: absolute;
  background-image: url("./images/evangelium.png");
  background-size: cover;
  background-position: center;
  z-index: 20;
  width: 2%;
  height: 4%;
}
#standevangelium {
  top: 61.5%;
  transform: rotate(180deg);
  left: 11.5%;
}
#standkreuz {
  top: 68%;
  left: 7%;
  width: 2.5%;
  height: 5%;
}

.kreuz {
  position: absolute;
  background-image: url("./images/kreuz.png");
  background-size: cover;
  background-position: center;
  z-index: 20;
}
#tragekreuz {
  left: 50%;
  transform: translateX(-50%);
  top: -50%;
  width: 50%;
  height: 100%;
}
.sitzend {
  background-color: lightblue !important;
}
.kniend {
  background-color: #6ba87e !important;
}
#stufe1 {
  position: absolute;
  background: #cecece;
  bottom: 0;
  height: 11.2%;
  width: 100%;
}
#stufe2 {
  position: absolute;
  background: #d6d6d6;
  bottom: 11.2%;
  height: 8.8%;
  width: 100%;
}
#stufe3 {
  background: #e6e6e6;
  bottom: 20%;
  height: 4%;
  width: 100%;
  position: absolute;
}
@media only screen and (min-width: 1000px) {
  #altarraum {
    height: 480px;
  }
  .dien-container {
    width: 800px;
    margin: auto;
  }
}

@media only screen and (max-width: 500px) {
  #state {
    font-size: 1.5rem;
  }
  #progressbar {
    height: 2rem;
  }
  .dienablauf-btn{
    padding: 0.2rem;
    margin: 0 0.5rem;
    flex: 1;
  }
  #bottomDisplay {
    padding: 0.2rem 0;
    width: 100%;
  }
  #descDisplay {
    padding: 0.2rem 0.3rem;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 7rem;
  }
  #description {
    font-size: 1.2rem;
  }
  .dien-menu {
    padding: 0.1rem 0;
  }
  .btnrow {
    margin: 0.2rem 0;
  }
  .ablauf.dien-display > div {
    width: 100%;
    padding: 0 0.2rem;
  }
  .ablauf.dien-display {
    display: block;
    padding-left: 2rem;
  }
}
